import { navigate } from "gatsby";
import * as React from "react"
import { useState } from "react";
import { useEffect } from "react";
import ClassBanner from "../../components/classes/ClassBanner";
import ClassesSlider from "../../components/classes/ClassesSlider";
import ClassTabs from "../../components/classes/ClassTabs";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout"
import VideoPlayer from "../../components/VideoPlayer";
import { useAuth } from "../../context/AuthContext";
import { getClass, useFetch } from "../../services/classes";

const ClassById = ({params}) => {
	const classId = params["id"];
	const {userData, authModal} = useAuth();
	
	const playerRef = React.useRef(null);

	const [source, setSource] = useState(false);

	const { data: classData, loading: loadingClass, error: classError} = useFetch(`classes/${classId}`);
	const { data: relatedClasses, loading: loadingRelatedClasses, error: relatedClassesError } = useFetch(`classes`);

	const play = (data, noAuth) => {
		// if(!userData?.subscription && !noAuth) {
		// 	navigate("/subscribe?ref=class/" + classId);
		// 	return;
		// }
		
		setSource({
			video: {
				url: data.url,
				title: data.title,
				lessonId: data.lessonId,
				order: data.order,
				subtitles: data?.subtitles,
				classId,
				lastMillis: data.isDone ? 0 : data.lastMillis
			}
		})
	}

	const onClose = () => {
		setSource(false)
	}


	return (
		<Layout 
			loading={loadingClass} 
			seoTitle={loadingClass ? "loading..." : classData?.name}
			allowMobile={false}
		>
			{!!source && <VideoPlayer source={source} onClose={onClose} />}
			<ClassBanner 
				cover={classData?.cover?.url}
				nameFont={classData?.nameFont}
				name={classData?.name}
				objective={classData?.objective}
				play={play}
				classData={classData}
			/>
			<Container maxWidth="max-w-2xl">
				<ClassTabs
					lessons={classData?.lessons}
					overview={{
						description: classData?.description,
						lessonsCount: classData?.lessons.length,
						certificate: classData?.certificate
					}}
					play={play}
				/>
			</Container>
			<Container>
				<br />
				<ClassesSlider 
					index={0}
					classes={relatedClasses} 
					sectionTitle="People Also Watched" 
					//openClass={setClassOpen}
				/>
				<br /><br />
			</Container>
			
		</Layout>
	)
}

export default ClassById
